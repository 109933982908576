<template>
 <page-layout
    :title="$t('communication.message_channel.detailsPage.title')"
    :has-back-button="true"
  >
     <template #header-actions>
      <base-button
        type="primary"
        :label="$t('communication.message_channel.createPage.button_save')"
        :loading='saving'
        @click="saveChange"
      />
    </template>
  <base-section>
    <el-alert
      type="info"
      :closable="false"
      class="view-admin__alert"
      show-icon>
      <span v-html="$t('communication.message_channel.createPage.alert_text')"></span>
    </el-alert>
  </base-section>
   <el-form>
  <base-section>
  <el-row>
     <el-col :span='12'>
        <base-input
         :key='messageChannelNameKey'
         v-model='messageChannelName'
         :label="$t('communication.message_channel.createPage.input_name.label')"
         :placeholder="$t('communication.message_channel.createPage.input_name.placeholder')"
         :info="$t('communication.message_channel.createPage.input_name.info')"
        >
        </base-input>

     </el-col>

  </el-row>
  <el-row>
     <el-col :span='12'>
           <base-translations
           :key='transKey'
           v-model='translations'
          ></base-translations>
    </el-col>
  </el-row>

  <el-row>
    <el-col :span="12">
          <base-select
           :label="$t('communication.message_channel.createPage.selects.admin')"
           :key='selectedAdmin'
            v-model="selectedAdmin"
            :placeholder="$t('communication.message_channel.createPage.select.admin')"
            :items="destinationUsers"
             filterable
         >
               <template v-slot:option="{ items }">
                <el-option
                  v-for="(item, index) in items"
                  :key="item.destinationUser.ospId + index"
                  :value="item.destinationUser.ospId"
                  :label="item.userLogin"
                >
                   <div class="filters__select-option">
                    <i class="ri-contacts-line"></i>
                    {{ item.userLogin  }}
                  </div>
                </el-option>
              </template>
            </base-select>

        <p class='el-select__info'>
          {{$t('communication.message_channel.createPage.select.description')}}
         </p>
    </el-col>
  </el-row>
  <el-row>
     <el-col :span='12'>
           <base-checkbox
           :key='allowReply + "2"'
           :label="$t('communication.message_channel.createPage.checkbox__first.label')"
           :explanation="$t('communication.message_channel.createPage.checkbox__first.exp')"
           v-model='allowReply'
          ></base-checkbox>
           <base-checkbox
           :key='showInApp + "1"'
           :label="$t('communication.message_channel.createPage.checkbox__second.label')"
           :explanation="$t('communication.message_channel.createPage.checkbox__second.exp')"
            v-model="showInApp"
          ></base-checkbox>
    </el-col>
  </el-row>
  </base-section>
  </el-form>

 </page-layout>

</template>

<script>
import PageLayout from '../../layouts/PageLayout'
import MessagesChannel from '@/mixins/MessagesChannel'

export default {
  name: 'CommunicationMessageChannelsDetails',
  components: {
    PageLayout
  },
  mixins: [MessagesChannel],
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.selectedAdmin = to.query.destinationUser.ospId
      vm.messageChannelName = to.query.label.defaultValue
      vm.messageChannelNameKey = to.query.label.defaultValue
      vm.showInApp = to.query.showInApp
      vm.allowReply = to.query.allowReply
      vm.defaultCorporateOspId = to.query.owner.ospId
      vm.transKey = to.query.owner.ospId
      vm.translations = to.query.label.labelTranslations
    })
  },
  mounted () {
    this.fetchDestinationUsers(this.defaultCorporateOspId)
  }
}
</script>
