<template>
  <page-layout
    :title="$t('communication.message_channel_tab.title')"
    :has-back-button="true"
    :fixed-footer="!!totalPages && totalPages > 1"
  >
    <template #header-actions>
      <base-button
        :key="key"
        :label="$t('communication.message_channel.view_all_page.new_channel_button')"
        type="primary"
        @click="$router.push({ name: 'CommunicationMessageChannelsAdd' })"
      />
    </template>
    <router-view
      ref="routerView"
      :key="$route.name"
      :totalPages.sync="totalPages"
      :pagination.sync="pagination"
    />
    <template #footer v-if="totalPages && totalPages > 1">
      <base-pagination
        :key="'pagination_' + $route.name"
        v-model="pagination.page"
        :pages="totalPages"
      />
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '../layouts/PageLayout'

export default {
  name: 'CommunicationLayout',
  components: {
    PageLayout
  },
  props: {
    emitBack: {
      typ: Boolean,
      default: false
    }
  },
  data () {
    return {
      key: '',
      activeName: '',
      totalPages: 0,
      pagination: {
        page: 0,
        size: 15,
        sort: {}
      }
    }
  },
  methods: {},
  watch: {
    '$route.name' () {
      this.totalPages = 0
      setTimeout(() => {
        this.key = this.$key()
      }, 50)
    }
  }
}
</script>
