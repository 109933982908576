<template>
  <el-form>
    <base-section direction-column class="message-add-form" :with-border="false">
      <div class="message-add-form__inputs" v-for="(message, i) in messageData" :key="message.id">
        <div class="message-add-form__title">
          <span class="title-text">
            {{ $t('communication.send_message.step_3.content') }}
          </span>
          <communication-language-select
            :message-lang.sync="message.languageCode"
            :select-items="selectItems"
            :lang-codes="langCodes"
          />
          <span
            v-if="showRemoveButton"
            class="remove-translation-button"
            @click="removeTranslation(message.id)"
          >
            <i class="ri-delete-bin-2-line"></i>
          </span>
        </div>
        <base-input
          v-model="message.title"
          :label="$t('communication.send_message.title.title')"
          maxlength="30"
          :show-word-limit="true"
          required
          :placeholder="$t('communication.send_message.title.placeholder')"
          :max-width="450"
          :validations="validations.messageData.title"
        />
        <p
          v-if="
            validations.messageData.$each[i].title.$invalid &&
            validations.messageData.$each[i].title.$dirty
          "
          class="error validation-error"
        >
          {{ $t('validations.required.without_label') }}
        </p>
        <base-input
          v-model="message.body"
          type="textarea"
          required
          :label="$t('communication.send_message.body.title')"
          :autosize="{ minRows: 5, maxRows: 8 }"
          :placeholder="$t('communication.send_message.body.placeholder')"
          :max-width="450"
          :validations="validations.messageData.body"
        />
        <p
          v-if="
            validations.messageData.$each[i].body.$invalid &&
            validations.messageData.$each[i].body.$dirty
          "
          class="error validation-error"
        >
          {{ $t('validations.required.without_label') }}
        </p>
      </div>

      <base-button
        v-if="showAddButton"
        class="add-circle-button"
        prepend-icon="ri-add-line"
        type="primary"
        :round="false"
        circle
        @click="addTranslation"
      ></base-button>
    </base-section>
  </el-form>
</template>

<script>
import CommunicationLanguageSelect from './CommunicationLanguageSelect.vue'

export default {
  name: 'CommunicationAddMessageWrite',
  components: { CommunicationLanguageSelect },
  props: {
    validations: {
      type: Object,
      default: () => ({})
    },
    messageData: {
      type: Array,
      default: () => []
    },
    selectItems: {
      type: Array,
      default: () => []
    },
    langCodes: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {}
  },
  computed: {
    showAddButton () {
      return this.messageData.length < Object.keys(this.langCodes).length
    },
    showRemoveButton () {
      return this.messageData.length > 1
    }
  },
  methods: {
    addTranslation () {
      this.$emit('addLanguage')
    },
    removeTranslation (id) {
      this.$emit('removeTranslation', id)
    }
  }
}
</script>

<style lang="scss" scoped>
.message-add-form {
  .message-add-form__title {
    display: flex;
    gap: 20px;

    .title-text {
      font-weight: 600;
      font-size: 15px;
      color: #282c36;
      padding-right: 8px;
      padding: 0;
      line-height: 30px;
    }

    .remove-translation-button {
      line-height: 30px;
      font-size: 18px;
      cursor: pointer;
    }
  }
  .message-add-form__inputs {
    margin-bottom: 24px;
  }
}
</style>
