import FinancePages from '@/mixins/FinancePages'
import MessageChannelRepository from '@/app/repositories/MessageChannelRepository'
import { required } from 'vuelidate/lib/validators'

const messagesChannelRepository = new MessageChannelRepository()
export default {
  name: 'MessagesChannel',
  mixins: [FinancePages],
  data () {
    return {
      saving: false,
      loadData: false,
      messageChannelName: '',
      selectedAdmin: '',
      destinationUsers: [],
      translations: [],
      allowReply: false,
      showInApp: false,
      transKey: '',
      messageChannelNameKey: '',
      fromCreateMessage: false
    }
  },
  validations: {
    selectedAdmin: {
      required
    },
    messageChannelName: {
      required
    },
    corporateOspId: {
      required
    }
  },
  computed: {
    filter () {
      const filter = {
        allowReply: this.allowReply,
        destinationUser: {
          ospId: this.selectedAdmin
        },
        label: {
          defaultValue: this.messageChannelName,
          labelTranslations: [
            ...this.translations
          ]
        },
        showInApp: this.showInApp
      }
      const ownerOspId = this.defaultCorporateOspId || this.corporateOspId || null
      if (ownerOspId) {
        filter.owner = { ospId: ownerOspId }
      }
      return filter
    },
    getDestinationUsers () {
      return Array.from(new Set(this.destinationUsers.map(JSON.stringify))).map(JSON.parse)
    }
  },
  methods: {
    setDestinationUsers (users) {
      this.destinationUsers = users
    },
    fetchDestinationUsers (id) {
      messagesChannelRepository.getDestinationUsers({ ospId: id }, {
        onSuccess: (res) => {
          this.setDestinationUsers(res)
        }
      })
    },
    createNewChannel () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.saving = true
        messagesChannelRepository.addChannel(this.filter, {
          successNotification: this.$t('communication.create_channel.notification'),
          onSuccess: () => {
            if (this.fromCreateMessage) {
              return this.$router.push({ name: 'CommunicationMessagesAdd' })
            }
            return this.$router.back()
          },
          onFinally: () => {
            this.saving = false
          }
        })
      }
    },
    saveChange () {
      this.saving = true
      messagesChannelRepository.updateMessageChannel(this.filter, {
        successNotification: this.$t('communication.update_channel.notification'),
        onSuccess: () => {
          this.$router.back()
        },
        onFinally: () => {
          this.saving = false
        }
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      vm.fromCreateMessage = to.params.fromCreateMessage
    })
  }
}
