import api from '@/api'
import Repository from './Repository'
import MessageChannel from '@/app/models/MessageChannel'

class MessageChannelRepository extends Repository {
  constructor () {
    super(MessageChannel)
  }

  getForUser (options) {
    const optionsData = this.addWrapper(options)
    return api.communication.getMessageChannelForUser(optionsData)
  }

  updateMessageChannel (form, options) {
    return api.communication.updateMessageChannel(form, options)
  }

  getDestinationUsers (form, options) {
    return api.communication.getDestinationUsers(form, options)
  }

  addChannel (form, options) {
    return api.communication.addChannel(form, options)
  }
}

export default MessageChannelRepository
