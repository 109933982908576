<template>
  <page-layout
    class="send-message-page"
    :has-back-button="true"
    :title="$t('communication.send_message_page.title')"
    :emit-back="true"
    @back="back"
  >
    <template #header-actions>
      <base-button
        type="primary"
        :label="$t('communication.send_message.button_step_3')"
        :loading="loading"
        @click="submit"
      />
    </template>

    <el-form
      id="sendMessageForm"
      ref="sendMessageForm"
      :model="form"
      :key="formKey"
      autocomplete="off"
      @submit.prevent.native="null"
    >
      <base-section direction-column :with-border="false">
        <base-checkbox
          v-model="form.showOnHomeScreen"
          :label="$t('communication.send_message.step_3.show_home_screen.label')"
          :explanation="$t('communication.send_message.step_3.show_home_screen.explanation')"
        />
        <template v-if="form.showOnHomeScreen">
          <base-datepicker
            v-model="form.showOnHomeScreenUntil"
            type="datetime"
            format="dd/MM/yyyy   HH:mm"
            placeholder="dd/mm/yyyy   hr/min"
            default-time="23:59:00"
            :label="$t('communication.send_message.step_3.date')"
            :max-width="200"
            :value-format="null"
            :picker-options="pickerOptions"
          />
          <p v-if="$v.form.$invalid && $v.form.$dirty" class="error validation-error">
            {{ $t('validations.required.without_label') }}
          </p>
        </template>
      </base-section>

      <base-section
        :title="$t('communication.send_message.recipients.section_title')"
        direction-column
        :with-border="false"
      >
        <base-checkbox-group
          :form.sync="form.recipients.recipients"
          :data="checkboxesData"
          :default-checked="defaultChecked"
          filterable
        ></base-checkbox-group>
      </base-section>

      <base-section
        :title="$t('communication.send_message.recipient_filters.section_title')"
        direction-column
        :with-border="false"
      >
        <base-select
          v-model="form.recipients.recipientFilters"
          :items="companyTypes"
          prop="recipientFilters"
          name="recipientFilters"
          :max-width="450"
          multiple
          collapse-tags
        ></base-select>
      </base-section>

      <communication-add-message-write
        :message-data="messageData"
        :select-items="langSelectItems"
        :lang-codes="langCodes"
        :validations="$v"
        @addLanguage="addMessageLanguage"
        @removeTranslation="removeTranslation"
      />
    </el-form>
  </page-layout>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators'
import { invert } from 'lodash'
import PageLayout from '@/layouts/PageLayout'
import CommunicationAddMessageWrite from '../../components/Communication/CommunicationAddMessageWrite.vue'
import CancelRequest from '@/mixins/CancelRequest'
import { DATE_TIME_ISO } from '@/app/helpers'
import Api from '@/app/services/ApiService/Api'

/** POR-640 - "The sender can be hard-coded as it will be always the same for now." */
const senderOspId = 'OlympusMobility.CORPORATE_ACCOUNT.OlympusMobility.USER.admin'
/** lang codes are different in API request and language select */
const langCodes = { be: 'nl', fr: 'fr', gb: 'en' }
const defaultForm = () => ({
  titles: {},
  bodies: {},
  recipients: {
    recipients: [],
    recipientFilters: []
  },
  sender: {
    ospId: senderOspId
  },
  showOnHomeScreen: true,
  showOnHomeScreenUntil: ''
})

export default {
  name: 'CommunicationMessageAdd',
  components: {
    PageLayout,
    CommunicationAddMessageWrite
  },
  mixins: [CancelRequest],
  data () {
    return {
      checkboxesData: [],
      messageRecipients: [],
      messageData: [
        {
          languageCode: 'nl',
          title: '',
          body: '',
          id: this.$key()
        }
      ],
      form: defaultForm(),
      defaultChecked: [],
      langCodes,
      loading: false,
      formKey: null
    }
  },
  validations: {
    form: {
      showOnHomeScreenUntil: {
        required: requiredIf(function (nestedModel) {
          return nestedModel.showOnHomeScreen
        })
      }
    },
    messageData: {
      $each: {
        title: {
          required
        },
        body: {
          required
        }
      }
    }
  },
  computed: {
    getForm () {
      this.messageData.forEach(trans => {
        this.form.bodies[trans.languageCode] = trans.body
        this.form.titles[trans.languageCode] = trans.title
      })
      return {
        ...this.form,
        showOnHomeScreenUntil: this.form.showOnHomeScreenUntil
          ? this.$date.fromJSDate(this.form.showOnHomeScreenUntil).toFormat(DATE_TIME_ISO)
          : null,
        recipients: {
          recipients: this.form.recipients.recipients.map(r => ({ ospId: r })),
          recipientFilters: this.form.recipients.recipientFilters.map(v => ({ companyType: v }))
        }
      }
    },
    pickerOptions () {
      return {
        disabledDate: value => {
          return value < this.$date.local().minus({ day: 1 })
        }
      }
    },
    selectedLanguages () {
      const invertedCodes = invert(this.langCodes)
      return this.messageData.map(el => invertedCodes[el.languageCode])
    },
    langSelectItems () {
      return Object.keys(this.langCodes).filter(el => !this.selectedLanguages.includes(el))
    },
    nextLang () {
      return this.langSelectItems[0]
    },
    companyTypes () {
      return [
        {
          value: 'BUSINESS_MOBILITY_EMPLOYEES',
          label: this.$t('communication.send_message.recipient_filters.business_employees')
        },
        {
          value: 'BUSINESS_MOBILITY_BUSINESS_OWNERS',
          label: this.$t('communication.send_message.recipient_filters.business_owners')
        },
        {
          value: 'OLYMPUS_ONLINE',
          label: this.$t('communication.send_message.recipient_filters.olympus_online')
        }
      ]
    }
  },
  methods: {
    back () {
      this.$router.back()
    },
    async fetchRecipients () {
      await this.$api.communication.getMessagingRecipients({
        onSuccess: res => {
          this.messageRecipients = res
          this.checkboxesData = res.map(recipient => ({
            key: recipient.ospId,
            label: recipient.name
          }))
          const getOspIds = recipients => recipients.map(recipient => recipient.ospId)
          this.form.recipients.recipients = getOspIds(res)
          this.defaultChecked = getOspIds(res)
        }
      })
    },
    addMessageLanguage () {
      this.messageData.push({
        languageCode: this.langCodes[this.nextLang],
        title: '',
        body: '',
        id: this.$key()
      })
    },
    removeTranslation (id) {
      this.messageData = this.messageData.filter(el => el.id !== id)
    },
    submit () {
      const ignoredError = 'SECURITY_RULE_VIOLATION'
      this.$v.$touch()
      if (this.$v.$invalid) {
        return false
      }
      this.loading = true
      this.$api.communication.sendMessage(this.getForm, {
        ignoreErrors: [ignoredError],
        successNotification: this.$t('communication.send_message.success_notification'),
        onSuccess: () => {
          this.messageData = [
            {
              languageCode: 'nl',
              title: '',
              body: '',
              id: this.$key()
            }
          ]
          this.form = defaultForm()
          this.formKey = this.$key()
          this.$v.$reset()
        },
        onError: e => {
          if (e.getCode() === ignoredError) {
            Api.pushMessage(e.getMessage(), 'error')
          }
        },
        onFinally: () => {
          this.loading = false
        }
      })
    }
  },
  async mounted () {
    await this.fetchRecipients()
  }
}
</script>

<style lang="scss" scoped>
.send-message-page {
  :deep(.base-section__title) {
    width: 100%;
    max-width: 120px;
  }

  :deep(.el-form-item__label) {
    float: none;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }
  :deep(.base-section) {
    padding: 15px 0;
  }
  #sendMessageForm {
    margin-top: 12px;
  }
}
</style>
