<template>
  <el-form ref="form" id="messageChannelsPage">
    <el-alert type="info" :closable="false" show-icon>
      <span v-html="$t('communication.message_channel.view_all_page.alert')"></span>
    </el-alert>
    <br />
    <el-table
      id="appUsersTable"
      ref="table"
      :data.sync="tableData"
      :empty-text="$t('general.empty_table')"
      :default-sort="pagination.sort"
      row-class-name="table-row"
      @sort-change="sortChange"
      style="width: 100%"
    >
      <el-table-column
        prop="name"
        :label="$t('general.input_label.name')"
        :show-overflow-tooltip="true"
        min-width="180"
        sortable="sortChange"
      ></el-table-column>
      <el-table-column
        prop="destinationUserExtId"
        :label="$t('communication.message_channel.view_all_page.table.recipient')"
        :show-overflow-tooltip="true"
        min-width="200"
        sortable="sortChange"
      ></el-table-column>
      <el-table-column
        prop="allowReply"
        :label="$t('communication.message_channel.view_all_page.table.allowReply')"
        min-width="110"
        sortable="sortChange"
      >
        <template slot-scope="{row}">
          <base-checkbox
            :key="'allowReply' + row.name + tableKey"
            :value="row.allowReply"
            @input="v => changeAllowReplay(row, v)"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="showInApp"
        :label="$t('communication.message_channel.view_all_page.table.showInApp')"
        min-width="110"
        sortable="sortChange"
      >
        <template slot-scope="{row}">
          <base-checkbox
            :key="'showInApp' + row.name + tableKey"
            :value="row.showInApp"
            @input="v => changeShowInApp(row, v)"
          />
        </template>
      </el-table-column>
      <el-table-column fixed="right" class-name="actions-cell" width="180">
        <template slot-scope="{row}">
          <base-button
            @click.stop="showDetails(row)"
            size="small"
            :label="$t('communication.message_channel.view_all_page.table.see_details')"
          />
          <base-button
            @click.stop="removeChannel(row)"
            size="small"
            prepend-icon="ri-delete-bin-2-line"
          />
        </template>
      </el-table-column>
    </el-table>
  </el-form>
</template>

<script>
import CancelRequest from '@/mixins/CancelRequest'
import TableLoader from '@/mixins/TableLoader'
import MessageChannelRepository from '@/app/repositories/MessageChannelRepository'
import { orderBy } from 'lodash'

const messageChannelRepository = new MessageChannelRepository()

const defaultSort = { order: 'ascending', prop: 'name' }

export default {
  name: 'CommunicationMessageChannelsViewAll',
  mixins: [CancelRequest, TableLoader],
  props: ['totalPages', 'pagination'],
  data () {
    return {
      form: {},
      dirtyTableData: [],
      tableKey: null
    }
  },
  computed: {
    sortedTableData () {
      const prop = this.pagination.sort.prop
      const order =
        this.pagination.sort.order === 'descending' ? 'desc' : 'asc'
      return orderBy(this.dirtyTableData, prop, order)
    },
    tableData () {
      return this.sortedTableData.slice(
        this.pagination.page * this.pagination.size,
        (this.pagination.page + 1) * this.pagination.size
      )
    }
  },
  methods: {
    changeAllowReplay (messageChannel, value) {
      messageChannel.updateAllowReply(value, {
        onError: () => {
          this.tableKey = this.$key()
        }
      })
    },
    changeShowInApp (messageChannel, value) {
      messageChannel.updateShowInApp(value, {
        onError: () => {
          this.tableKey = this.$key()
        }
      })
    },
    async fetchData () {
      const loading = this.TableLoader_show()
      try {
        await messageChannelRepository.getForUser({
          cancelToken: this.CancelRequest_token(),
          onSuccess: (response) => {
            this.setData(response)
          }
        })
      } catch (e) {
        console.log(e)
      }
      loading.close()
      return true
    },
    showDetails (row) {
      this.$router.push({
        name: 'CommunicationMessageChannelsDetails',
        query: { ...row }
      })
    },
    setData (content) {
      this.dirtyTableData = content
      const totalPages = Math.ceil(content.length / this.pagination.size)
      this.$emit('update:totalPages', totalPages)
    },
    sortChange (sort) {
      this.pagination.sort = sort
    },
    removeChannel (row) {
      this.$api.communication.deleteChannel(row, {
        successNotification: this.$t('communication.channel_delete.notification'),
        onSuccess: () => {
          this.fetchData()
        }
      })
    }
  },
  mounted () {
    this.$nextTick().then(() => {
      this.fetchData()
    })
    this.$emit('update:pagination', {
      page: 0,
      size: 15,
      sort: { ...defaultSort }
    })
  }
}
</script>
