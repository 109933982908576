<template>
  <!-- based on vue-tel-input -->
  <div class="vue-tel-input" v-click-outside="clickOutsideHandler">
    <div class="vti__dropdown">
      <span class="vti__selection" @click="dropdownToggle">
        <div :class="['vti__flag', currentLanguage]"></div>
        <span v-if="selectItems.length" :class="['vti__dropdown-arrow', isOpen && 'rotate']"
          >▼</span
        >
      </span>

      <ul v-if="selectItems.length" :class="['vti__dropdown-list below', !isOpen && 'hide']">
        <li
          v-for="lang in selectItems"
          :key="lang"
          class="vti__dropdown-item"
          @click="selectLanguage(lang)"
        >
          <div :class="['vti__flag', lang]"></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import { invert } from 'lodash'

export default {
  name: 'CommunicationLanguageSelect',
  mixins: [VueTelInput],
  props: {
    selectItems: {
      type: Array,
      default: () => []
    },
    langCodes: {
      type: Object,
      default: () => ({})
    },
    messageLang: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    currentLanguage () {
      const invertedLangCodes = invert(this.langCodes)
      return invertedLangCodes[this.messageLang]
    }
  },
  methods: {
    dropdownToggle () {
      this.isOpen = !this.isOpen
    },
    selectLanguage (lang) {
      this.$emit('update:message-lang', this.langCodes[lang])
      this.isOpen = false
    },
    clickOutsideHandler () {
      this.isOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
.vue-tel-input {
  width: auto;
}
.rotate {
  transform: rotate(180deg);
}
.hide {
  display: none;
}
.vti__dropdown-list {
  width: auto;
}
.vti__dropdown-item:hover {
  background-color: #f5f7fa;
  color: var(--brand-color);
}
</style>
