import { mapActions, mapGetters } from 'vuex'
import store from '@/store'

import CorporateAccountRepository from '@/app/repositories/CorporateAccountRepository'

const corporateAccountRepository = new CorporateAccountRepository()

export default {
  name: 'FinancePages',
  data () {
    return {
      corporateList: [],
      partnerSearch: '',
      corporateOspId: '',
      defaultCorporateOspId: '',
      defaultPagination: {
        page: 0,
        size: 1000,
        sort: null
      },
      keySelect: ''
    }
  },
  computed: {
    ...mapGetters({
      allPartners: 'partner/allPartners',
      getcompanyPaymentType: 'budget/getcompanyPaymentType',
      name: 'corporateAccount/name',
      selectedCorporateName: 'corporateAccount/selectedCorporateName',

      userCorporateAccount: 'corporateAccount/userCorporateAccount',
      selectedAccountCorporate: 'corporateAccount/selectedAccountCorporate',

      selectedPartnerOspId: 'corporateAccount/selectedPartnerOspId',
      userPartnerOspId: 'corporateAccount/userPartnerOspId'
    }),
    $_filterCorporate () {
      return {
        active: true,
        pagination: { ...this.defaultPagination },
        partnerOspId: {
          ospId: this.partnerSearch
        }
      }
    },
    isPrepaidCompany () {
      return this.getcompanyPaymentType(this.defaultCorporateOspId || this.corporateOspId) === 'PREPAID'
    },
    getDefaultPartner () {
      return this.selectedPartnerOspId ? this.selectedPartnerOspId : this.userPartnerOspId
    },
    getDefaultCorporateOspId () {
      if (this.selectedAccountCorporate) {
        return this.selectedAccountCorporate.ospId
      } else {
        return this.userCorporateAccount.corporateAccount.ospId
      }
    },
    getDefaultCorporateName () {
      return this.selectedCorporateName ? this.selectedCorporateName : this.name
    }
  },
  methods: {
    ...mapActions({
      fetchAllActivePartners: 'partner/fetchAllActivePartners'
    }),
    clearAndFetchPartnerData (partner) {
      this.corporateOspId = ''
      this.defaultCorporateOspId = ''
      this.partnerSearch = partner
      this.keySelect = partner
      return this.fetchCorporate()
    },
    fetchCorporate () {
      return corporateAccountRepository.getByFilter(this.$_filterCorporate, {
        onSuccess: ({ content }) => {
          this.corporateList = content
        }
      })
    }
  },

  beforeMount () {
    this.partnerSearch = this.getDefaultPartner
    this.defaultCorporateOspId = this.getDefaultCorporateOspId
    this.corporateOspId = this.getDefaultCorporateName
    store.dispatch('budget/getCompanyBudget', { ospId: this.defaultCorporateOspId })
  },
  mounted () {
    this.fetchAllActivePartners()
    this.fetchCorporate()
  }
}
