<template>
  <page-layout
    :title="$t('communication.message_channel.createPage.title')"
    :has-back-button="true"
    id="createChannelPage"
  >
    <template #header-actions>
      <base-button
        type="primary"
        :label="$t('communication.message_channel.createPage.button_create.text')"
        :loading="saving"
        @click="createNewChannel"
      />
    </template>
    <base-section>
      <el-alert type="info" :closable="false" class="view-admin__alert" show-icon>
        <span v-html="$t('communication.message_channel.createPage.alert_text')"></span>
      </el-alert>
    </base-section>
    <el-form>
      <base-section>
        <el-row>
          <el-col :span="12">
            <base-input
              v-model="messageChannelName"
              required
              :validations="$v.messageChannelName"
              :label="$t('communication.message_channel.createPage.input_name.label')"
              :placeholder="$t('communication.message_channel.createPage.input_name.placeholder')"
              :info="$t('communication.message_channel.createPage.input_name.info')"
            ></base-input>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <base-translations  :label="$t('general.label.translations')" v-model="translations"></base-translations>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <base-select
              :label="$t('communication.message_channel.createPage.select.msp')"
              v-if="isOspAdmin"
              v-model="partnerSearch"
              :placeholder="$t('communication.message_channel.createPage.select.msp')"
              :items="allPartners"
              :change="partnerChanged"
              filterable
            >
              <template v-slot:option="{ items }">
                <el-option
                  v-for="item in items"
                  :key="item.extId"
                  :value="item.ospId"
                  :label="item.name"
                >
                  <div class="filters__select-option">
                    <i class="ri-building-4-line"></i>
                    {{ item.name }}
                  </div>
                </el-option>
              </template>
            </base-select>
            <base-select
              :key="corporateKey"
              :label="$t('communication.message_channel.createPage.select.corporate')"
              v-if="isMsp"
              v-model="corporateOspId"
              :placeholder="$t('communication.message_channel.createPage.select.corporate')"
              :items="corporateList"
              :change="corporateChanged"
              :validations="$v.corporateOspId"
              filterable
              required
            >
              <template v-slot:option="{ items }">
                <el-option
                  v-for="item in items"
                  :key="item.extId"
                  :value="item.ospId"
                  :label="item.name"
                >
                  <div class="filters__select-option">
                    <i class="ri-building-4-line"></i>
                    {{ item.name }}
                  </div>
                </el-option>
              </template>
            </base-select>
            <base-select
              :key="adminKey"
              :label="$t('communication.message_channel.createPage.selects.admin')"
              v-model="selectedAdmin"
              required
              :validations="$v.selectedAdmin"
              :placeholder="$t('communication.message_channel.createPage.select.admin')"
              :items="getDestinationUsers"
              filterable
            >
              <template v-slot:option="{ items }">
                <el-option
                  v-for="(item, index) in items"
                  :key="item.destinationUser.ospId + index"
                  :value="item.destinationUser.ospId"
                  :label="item.userLogin"
                >
                  <div class="filters__select-option">
                    <i class="ri-contacts-line"></i>
                    {{ item.userLogin }}
                  </div>
                </el-option>
              </template>
            </base-select>

            <p
              class="el-select__info"
            >{{ $t('communication.message_channel.createPage.select.description') }}</p>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <base-checkbox
              :label="$t('communication.message_channel.createPage.checkbox__first.label')"
              :explanation="$t('communication.message_channel.createPage.checkbox__first.exp')"
              v-model="allowReply"
            ></base-checkbox>
            <base-checkbox
              :label="$t('communication.message_channel.createPage.checkbox__second.label')"
              :explanation="$t('communication.message_channel.createPage.checkbox__second.exp')"
              v-model="showInApp"
            ></base-checkbox>
          </el-col>
        </el-row>
      </base-section>
    </el-form>
  </page-layout>
</template>

<script>
import PageLayout from '../../layouts/PageLayout'
import Auth from '@/app/services/AuthService/Auth'
import MessagesChannel from '@/mixins/MessagesChannel'

export default {
  name: 'CommunicationMessageChannelsAdd',
  components: {
    PageLayout
  },
  data () {
    return {
      corporateKey: null,
      adminKey: null
    }
  },
  mixins: [MessagesChannel],
  computed: {
    isOspAdmin () {
      return Auth.isOspAdmin()
    },
    isMsp () {
      return Auth.isMsp()
    }
  },
  methods: {
    async partnerChanged (partner) {
      await this.clearAndFetchPartnerData(partner)
      this.corporateOspId = null
      this.selectedAdmin = null
      this.corporateKey = this.$key()
      this.adminKey = partner
    },
    async corporateChanged (corporate) {
      await this.fetchDestinationUsers(corporate)
      this.selectedAdmin = null
      this.adminKey = corporate
    }
  },
  mounted () {
    this.fetchDestinationUsers(this.defaultCorporateOspId)
  }
}
</script>
