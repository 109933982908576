import Model from './Model'
import { trans } from '@/app/helpers'
import { last } from 'lodash'
import MessageChannelRepository from '@/app/repositories/MessageChannelRepository'

class MessageChannel extends Model {
  #repository = null

  constructor (data) {
    super(data)
    this.#repository = new MessageChannelRepository()
  }

  get name () {
    return trans(this.label)
  }

  get destinationUserExtId () {
    return last(this.destinationUser.ospId.split('.'))
  }

  updateAllowReply (value, options = {}) {
    const form = { ...this }
    form.allowReply = value
    this.#repository.updateMessageChannel(form, {
      ...options,
      onSuccess: response => {
        this.allowReply = value
        options.onSuccess && options.onSuccess(response)
      }
    })
  }

  updateShowInApp (value, options = {}) {
    const form = { ...this }
    form.showInApp = value
    this.#repository.updateMessageChannel(form, {
      ...options,
      onSuccess: response => {
        this.showInApp = value
        options.onSuccess && options.onSuccess(response)
      }
    })
  }
}

export default MessageChannel
