var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form',[_c('base-section',{staticClass:"message-add-form",attrs:{"direction-column":"","with-border":false}},[_vm._l((_vm.messageData),function(message,i){return _c('div',{key:message.id,staticClass:"message-add-form__inputs"},[_c('div',{staticClass:"message-add-form__title"},[_c('span',{staticClass:"title-text"},[_vm._v(" "+_vm._s(_vm.$t('communication.send_message.step_3.content'))+" ")]),_c('communication-language-select',{attrs:{"message-lang":message.languageCode,"select-items":_vm.selectItems,"lang-codes":_vm.langCodes},on:{"update:messageLang":function($event){return _vm.$set(message, "languageCode", $event)},"update:message-lang":function($event){return _vm.$set(message, "languageCode", $event)}}}),(_vm.showRemoveButton)?_c('span',{staticClass:"remove-translation-button",on:{"click":function($event){return _vm.removeTranslation(message.id)}}},[_c('i',{staticClass:"ri-delete-bin-2-line"})]):_vm._e()],1),_c('base-input',{attrs:{"label":_vm.$t('communication.send_message.title.title'),"maxlength":"30","show-word-limit":true,"required":"","placeholder":_vm.$t('communication.send_message.title.placeholder'),"max-width":450,"validations":_vm.validations.messageData.title},model:{value:(message.title),callback:function ($$v) {_vm.$set(message, "title", $$v)},expression:"message.title"}}),(
          _vm.validations.messageData.$each[i].title.$invalid &&
          _vm.validations.messageData.$each[i].title.$dirty
        )?_c('p',{staticClass:"error validation-error"},[_vm._v(" "+_vm._s(_vm.$t('validations.required.without_label'))+" ")]):_vm._e(),_c('base-input',{attrs:{"type":"textarea","required":"","label":_vm.$t('communication.send_message.body.title'),"autosize":{ minRows: 5, maxRows: 8 },"placeholder":_vm.$t('communication.send_message.body.placeholder'),"max-width":450,"validations":_vm.validations.messageData.body},model:{value:(message.body),callback:function ($$v) {_vm.$set(message, "body", $$v)},expression:"message.body"}}),(
          _vm.validations.messageData.$each[i].body.$invalid &&
          _vm.validations.messageData.$each[i].body.$dirty
        )?_c('p',{staticClass:"error validation-error"},[_vm._v(" "+_vm._s(_vm.$t('validations.required.without_label'))+" ")]):_vm._e()],1)}),(_vm.showAddButton)?_c('base-button',{staticClass:"add-circle-button",attrs:{"prepend-icon":"ri-add-line","type":"primary","round":false,"circle":""},on:{"click":_vm.addTranslation}}):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }